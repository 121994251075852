<template>
 <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11751_140117)">
     <path d="M7.5 0C3.3578 0 0 3.35792 0 7.5C0 11.6423 3.3578 15 7.5 15C11.6422 15 15 11.6423 15 7.5C15 3.35792 11.6422 0 7.5 0ZM7.5 3.75C8.01779 3.75 8.4375 4.16977 8.4375 4.6875C8.4375 5.20547 8.01779 5.625 7.5 5.625C6.98221 5.625 6.5625 5.20547 6.5625 4.6875C6.5625 4.16977 6.98221 3.75 7.5 3.75ZM8.90625 11.25H6.09375C5.83488 11.25 5.625 11.0404 5.625 10.7812C5.625 10.5224 5.83488 10.3125 6.09375 10.3125H6.5625V7.5H6.09375C5.83488 7.5 5.625 7.29035 5.625 7.03125C5.625 6.77238 5.83488 6.5625 6.09375 6.5625H7.96875C8.22762 6.5625 8.4375 6.77238 8.4375 7.03125V10.3125H8.90625C9.16512 10.3125 9.375 10.5224 9.375 10.7812C9.375 11.0404 9.16512 11.25 8.90625 11.25Z" fill="currentColor"/>
    </g>
    <defs>
    <clipPath id="clip0_11751_140117">
      <rect width="15" height="15" fill="white"/>
    </clipPath>
    </defs>
  </svg>

</template>

<script>
export default {
  name: 'IconInfoFilled'
}
</script>
